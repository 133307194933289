import {
    Box, Button,
    Center,
    ChakraProvider,
    ColorMode, Divider, extendTheme, Flex,
    Heading,
    HeadingProps, HStack, Text,
    useColorMode, VStack,
} from "@chakra-ui/react";
import React, {FC} from "react";
import ReactDOM from 'react-dom/client';
import "@fontsource/lato"
import reportWebVitals from "./reportWebVitals";
import '@fontsource-variable/josefin-sans';

type LogoProps = {
    size: HeadingProps["size"];
    shortVersion?: boolean;
    lineHeight?: HeadingProps["lineHeight"];
    colour?: "pink" | "purple" | "gradient";
    isHomeLink?: boolean;
};


const getColourProps = (colourType: LogoProps["colour"], colorMode: ColorMode): HeadingProps => {

    const {startColour, endColour} = (() => {
        if (colorMode === 'dark') return {
            startColour: 'pink.200',
            endColour: 'pink.400'
        }
        const endColour = "purple.600"
        const startColour = "pink.500"
        return {
            endColour,
            startColour
        }
    })()
    switch (colourType) {
        case "pink":
            return {
                color: startColour
            }
        case "purple":
            return {
                color: endColour
            }
        case "gradient":
            return {
                bgGradient: `linear(to-l, ${endColour}, ${startColour})`,
                bgClip: "text"
            }
        default:
            return getColourProps("gradient", colorMode)
    }
}
const Logo: FC<LogoProps> = ({
                                 lineHeight = "unset",
                                 size,
                                 shortVersion = false,
                                 colour = "gradient",
                                 isHomeLink = false
                             }) => {
    const {colorMode} = useColorMode()
    return <Heading
        {...getColourProps(colour, colorMode)}
        size={size}
        lineHeight={lineHeight}
    >
        [{shortVersion ? "SL" : "SalonLynk"}]
    </Heading>
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const theme = extendTheme({
    fonts: {
        heading: "Josefin Sans Variable",
        body: "Josefin Sans Variable",
    }
})
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Center w={'100vw'} mt={16}>
                <VStack w={'full'} maxW={'520px'} bg={'white'} px={{
                    base: 4,
                    md: 0
                }}>
                    <Logo size={"4xl"}/>
                    <Divider my={4}/>
                    <VStack alignItems={'flex-start'} spacing={8}>
                        <VStack w={'full'} alignItems={'flex-start'}>
                            <Text fontSize={"lg"} fontWeight={'bold'}>
                                Welcome to SalonLynk!
                            </Text>
                            <Text fontSize={"lg"}>
                                We're building the perfect booking system to connect clients and
                                beauty professionals.
                            </Text>
                        </VStack>
                        <VStack w={'full'} alignItems={'flex-start'}>
                            <Text fontSize={"lg"} fontWeight={'bold'}>
                                We want your input!
                            </Text>
                            <Text fontSize={"lg"}>
                                SalonLynk should work with you and not against you. Let us know your wishes and ideas so
                                that we can
                                make it happen.
                            </Text>
                        </VStack>
                        <VStack w={'full'} alignItems={'flex-start'}>
                            <Text fontSize={"lg"}>
                                Thank you and see you soon!
                            </Text>
                            <Text fontSize={"lg"}>
                                - Carin (Hairstylist) and Kain (Developer)
                            </Text>
                        </VStack>
                    </VStack>
                    <iframe src={"https://form.jotform.com/frisorcarin/SLnewsletter"} style={{
                        width: "100vw",
                        height: "100vh",
                        marginTop: "16px"
                    }}/>
                </VStack>
            </Center>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
